* , *:before, *:after { 
  box-sizing:border-box; 
  -moz-box-sizing:border-box; 
  -webkit-box-sizing:border-box; 
  -ms-box-sizing:border-box;
}

html, body {
  height: 100%;
  background-color: #3e719e;
  padding: 5px;
}

#root {
  height: 100%;
  width: 100%;
}

#accueil {
  height: 100%;
  width: 100%;
}

#accueil form {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

#accueil form label {
  font-size: larger;
  color: white;
}

#accueil form input {
  margin: 10px;
}

button, input[type=submit] {
  margin: 5px;
  padding: 10px 20px 10px 20px;
}

#game {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

#players {
  padding: 10px 30px 10px 30px;
  border-radius: 10px;
  background-color: #eee;
  box-shadow: 5px 5px 5px rgba(51, 51, 51, 0.61);
}

#players #header {
  font-size: larger;
  margin-bottom: 40px;
  font-weight: bold;
}

#players #curPlayer {
  font-weight: bold;
}

#gameZone {
  flex: 5;
  padding: 0 20px 0 20px;
}

#gameZone input[type=text] {
  margin: 10px;
}

#gameZone > div {
  display: table;
  border-radius: 10px;
  background-color: #eee;
  padding: 20px;
  box-shadow: 5px 5px 5px rgba(51, 51, 51, 0.61);
}

#options {
  margin-top: 50px;
}

#lienPartie {
  width: 300px;
}

#score {
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

#score p {
  text-align: center;
}

.player, .player-devineur, .player-offline {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.player {
  color: #333;
}

.player-offline {
  color: #F45A5E;
}

.player-devineur {
  background-color: #fae52b;
  color: black;
  padding: 2px 10px 2px 10px;
  border-radius: 15px;
}

.player-devineur #nomJoueurActif {
  font-weight: bold;
}

.player-devineur #joueurActif {
  font-size: smaller;
}

.clue {
  color: #fff;
  background-color: #6a6;
  padding: 10px;
  width: max-content;
}

.clue-selected {
  color: #fff;
  background-color: #F45A5E;
  padding: 10px;
  width: max-content;
}

#validerGuess {
  color: #fff;
  background-color: #6a6;
}

#skipGuess {
  color: #fff;
  background-color: #F45A5E;
}

#bienJoue {
  color: #fff;
  background-color: #6a6;
  padding: 10px;
  width: max-content;
}

#dommage {
  color: #fff;
  background-color: #F45A5E;
  padding: 10px;
  width: max-content;
}

#passe {
  color: #000;
  background-color: #fae207;
  padding: 10px;
  width: max-content;
}

.message-rouge {
  color: #F45A5E;
}

.timer {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.logo {
  font-size: 8em;
  font-weight: bold;
  color: rgb(255, 230, 0);
  -webkit-text-stroke: 2px rgba(51, 51, 51, 0.527);
}

#choixLeaderboard h4 {
  display: inline-block;
  text-decoration: underline;
  margin-left: 10px;
  margin-right: 10px;
}

#choixLeaderboard button {
  border: none;
  background: none !important;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.avatar {
  width: 102px;
  background-color: white;
  border: 1px solid #333;
  overflow: hidden;
  margin-left: 10px;
}
